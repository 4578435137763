import { combineReducers } from 'redux'
import { SET_NAV_DATA, SET_USER_DATA, SET_PLAN_DATA, USER_LOG_OUT } from './actionTypes.js'

const initialState = {
  collapsed: false, 
  smallScreen: false,
  moveToOpen: false,
  user: {
    name: ""
  },
  plan: {
    type: 0, //0: 免费会员, 1: 初级，2：高级
    chatBot: 1, // 免费：1个      
    fileNum: 1, // 免费：1个
    fileSize: 2*1024*1024, // 免费：2M
    message: 6, // 每日会话数量 接口判断
    characters: 100000, // 回答字符数
    api: false, // 是否允许调用接口
    embed: false, // 是否允许嵌入
  },
  used: {

  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NAV_DATA: {
      if (action.navData.collapsed !== undefined) {
        state.collapsed = action.navData.collapsed;
      }
      if (action.navData.smallScreen !== undefined) {
        state.smallScreen = action.navData.smallScreen;
      }
      if (action.navData.moveToOpen !== undefined) {
        state.moveToOpen = action.navData.moveToOpen;
      }
      return Object.assign({}, state)
    }
    case SET_USER_DATA: {
      state.user = action.userData;
      // console.log(state.userInfo)
      return Object.assign({}, state)
    }
    case SET_PLAN_DATA: {
      // state.plan = action.planData;
      switch (action.planData) {
        case 0: { // 免费
          state.plan = {
            type: 0,
            chatBot: 1,
            fileNum: 1,
            fileSize: 2*1024*1024,
            message: 6,
            characters: 100000,
            api: false,
            embed: false,
          }
          break;
        }
        case 1: { // 初级
          state.plan = {
            type: 1,
            chatBot: 3,
            fileNum: 10,
            fileSize: 10*1024*1024,
            message: 600,
            characters: 1000000,
            api: true,
            embed: true,
          }
          break;
        }
        case 2: { // 高级
          state.plan = {
            type: 1,
            chatBot: 10,
            fileNum: 20,
            fileSize: 20*1024*1024,
            message: 2000,
            characters: 10000000,
            api: true,
            embed: true,
          }
          break;
        }
        default: break;
      }
      return Object.assign({}, state)
    }
    case USER_LOG_OUT: {
      document.cookie = 'Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      localStorage.clear();
      if (action.logData === "redirect") {
        window.location.href = `https://www.copilot365.co/login?redirect_uri=${window.location.href}`;
      } else {
        window.location.href = 'https://www.copilot365.co/logout';
      }
      break;
    }
    default: {
      return state
    }
  }
}

const rootReducer = combineReducers({ reducer })

export default rootReducer
