import React from 'react'
import { MenuFoldOutlined, MenuUnfoldOutlined, UnorderedListOutlined, PlusOutlined, QuestionCircleOutlined, BulbOutlined, ReadOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
// import icon from '../images/icon.js';
// import fetch from '../fetch/axios';
import logo from '../images/Copilot365_logo.svg';
import '../style/nav.less';
import { createFromIconfontCN } from '@ant-design/icons';
const MyIcon = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4036116_bbbix2q5bs9.js', // 在 iconfont.cn 上生成
});

const { Sider } = Layout;

class NavigationBar extends React.Component {
  state = {
    pathname: "",
    selectedKeys: ['projects', 'dashboard'],
    role: "",
    lastOpen: ['square', 'dashboard']
  }
  componentDidMount() {
    console.log(this.props);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.pathname !== state.pathname) {
      return {
      }
    }
    if (props.user.role !== state.role) {
      return {
      }
    }
    return null
  }
  componentDidUpdate() {
    if (this.props.location.pathname !== this.state.pathname) {
      this.setState({ pathname: this.props.location.pathname }, () => {
        if (this.state.pathname === '/') {
          this.props.history.push('/square')
        } else {
          this.listenRoute(this.state.pathname);
        }
      })
    }
    if (this.props.user.role !== this.state.role) {
      this.setState({ role: this.props.user.role })
    }
  }

  listenRoute(path) {
    // console.log(path)
    if (path.includes('/create')) {
      this.setState({selectedKeys: ['create', 'dashboard']})
    } else if (path.includes('/projects/list')) {
      this.setState({selectedKeys: ['projects', 'dashboard']})
    } else if (path.includes('/chat-official')) {
      this.setState({selectedKeys: ['chat']})
    } else if (path.includes('/square')) {
      this.setState({selectedKeys: ['square']})
    } else if (path.includes('/s-create')) {
      this.setState({selectedKeys: ['s-create', 'square']})
    } else if (path.includes('/my-square')) {
      this.setState({selectedKeys: ['my-square', 'square']})
    } else if (path.includes('/welcome')) {
      this.setState({selectedKeys: ['welcome', 'support']})
    } else {
      this.setState({selectedKeys: ['dashboard']})
    }
  }

  changeNavPath(e) {
    // console.log(e);
    // console.log(this.props.history);
    if (e.key === 'create') {
      this.props.history.push('/create');
    } else if (e.key === 'projects') {
      this.props.history.push('/projects/list');
    } else if (e.key === 'chat') {
      this.props.history.push('/chat-official');
    } else if (e.key === "faq") {
      window.open("https://www.copilot365.co/faq")
    } else if (e.key === "contact") {
      window.open("https://www.copilot365.co/contact-us")
    } else if (e.key === "square") {
      this.props.history.push('/square');
    } else if (e.key === "s-create") {
      this.props.history.push('/s-create');
    } else if (e.key === "my-square") {
      this.props.history.push('/my-square');
    } else if (e.key === "welcome") {
      this.props.history.push('/welcome');
    }
    if (this.props.smallScreen) {
      this.props.setNavData({collapsed: true});
    }
  }

  changePath() {
    this.props.history.push('/square');
  }
  closeNav(e) {
    if (e.target?.className === "navigation" && this.props.smallScreen) {
      this.props.setNavData({collapsed: true});
    }
  }
  clickSubMenu(arr) {
    let lastArr = this.state.lastOpen;
    // console.log(lastArr)
    // 点开了广场页面
    if (arr.includes('square') && !lastArr.includes('square')) {
      this.props.history.push('/square')
    }
    this.setState({lastOpen: arr})
  }

  render() {
    const { collapsed, smallScreen, moveToOpen } = this.props;
    const squareArr = [
      {
        key: 'square',
        icon: <MyIcon className='leadIc' type="icon-fyguangchang" />,
        label: 'Square',
        // children: [
        //   {
        //     key: 's-create',
        //     icon: <PlusOutlined />,
        //     label: 'Create Copilot App',
        //   },
        //   {
        //     key: 'my-square',
        //     icon: <UnorderedListOutlined />,
        //     label: 'My Copilot App',
        //   }
        // ]
      }
    ]
    const menuArr = [
      {
        key: 'chat',
        icon: <MyIcon className='leadIc' type="icon-xiaoxi-zhihui" />,
        label: 'AI Assistant',
      },
      {
        key: 'dashboard',
        icon: <MyIcon className='leadIc' type="icon-liebiaoxingshi" />,
        label: 'Bot',
        children: [
          {
            key: 'create',
            icon: <PlusOutlined />,
            label: 'Create Bot',
          },
          {
            key: 'projects',
            icon: <UnorderedListOutlined />,
            label: 'My Bots',
          }
        ]
      },
      {
        key: 'support',
        icon: <MyIcon className='leadIc' type="icon-kefu" />,
        label: 'Support',
        children: [
          {
            key: 'welcome',
            icon: <ReadOutlined />,
            label: 'Read Me',
          },
          {
            key: 'faq',
            icon: <QuestionCircleOutlined />,
            label: 'FAQ',
          },
          {
            key: 'contact',
            icon: <BulbOutlined />,
            label: 'Contact Us',
          }
        ]
      }
    ]
    return (
      <div className="navigation" open={!collapsed} onClick={(e)=>{ this.closeNav(e); }}>
        <Sider
          trigger={null}
          breakpoint='xl'
          onBreakpoint={(broken) => {
            this.props.setNavData({collapsed: broken, smallScreen: broken});
          }}
          width={'16.25rem'}
          collapsedWidth={smallScreen ? 0: '5.25rem'}
          collapsible
          collapsed={collapsed}
          onMouseEnter={()=>{
            if (smallScreen) return;
            this.props.setNavData({collapsed: false})
          }}
          onMouseLeave={(e)=>{
            if (smallScreen || !moveToOpen) return;
            this.props.setNavData({collapsed: true})
          }}
          style={{ background: "#fff" }}
          >
          <div className={collapsed ? 'logo_box collapsed':'logo_box'}>
            <div className='icon_box' onClick={()=>{ this.changePath() }}>
              <img src={logo}  className='logo' alt=''></img>
            </div>
            {/* <CloseOutlined className='nav_close'/> */}
            {React.createElement(collapsed || moveToOpen ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'nav_close',
              onClick: () => {
                if (!collapsed && moveToOpen) {
                  this.props.setNavData({collapsed: false, moveToOpen: false})
                  return
                }
                this.props.setNavData({collapsed: !collapsed, moveToOpen: !collapsed})
              },
            })}
          </div>
          <Menu
            // theme="dark"
            mode="inline"
            // defaultSelectedKeys={}
            defaultOpenKeys={['square', 'dashboard', 'support']}
            selectedKeys={this.state.selectedKeys}
            style={{ fontSize: ".9375rem", color: "#6f6b7d" }}
            onClick={(e)=>{ this.changeNavPath(e) }}
            onOpenChange={(e)=>{ this.clickSubMenu(e) }}
            items={ this.state.role === "System" ?  [...squareArr, ...menuArr] : [...squareArr, ...menuArr]}
          />
        </Sider>
      </div>
    )
  }
}

export default NavigationBar