import { SET_NAV_DATA, SET_USER_DATA, SET_PLAN_DATA, USER_LOG_OUT } from './actionTypes.js'

export const setNavData = (navData) => ({
  type: SET_NAV_DATA,
  navData: navData
})
export const setUserData = (userData) => ({
  type: SET_USER_DATA,
  userData: userData
})
export const setPlanData = (planData) => ({
  type: SET_PLAN_DATA,
  planData: planData
})
export const userLogOut = (logData) => ({
  type: USER_LOG_OUT,
  logData: logData
})
