import React from 'react';
import ReactDOM from 'react-dom';
import Root from './router/AppRouter';
import { createStore } from 'redux'
import { Provider } from "react-redux";
// import 'antd/dist/antd.css';
import './style/global.less';
import reducer from "./redux/reducer";
import reportWebVitals from './reportWebVitals';

let store = createStore(reducer)

ReactDOM.render(<Provider store={store}><Root /></Provider>, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
reportWebVitals();
