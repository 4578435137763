/**
 * @Author: li
 * @Date: 2019
 * @Description: pages route ??
 */
// pagerouter表示页面的路由
import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import asyncComponent from './AsyncComponent'
import App from '../pages/app';

const WelcomePage = asyncComponent(() => import('../pages/other/welcome'))
const CreateProject = asyncComponent(() => import('../pages/creactProject/createProject'))
const MyProject = asyncComponent(() => import('../pages/myProject/myProject'))
const AskMe = asyncComponent(() => import('../pages/chat/askMe'))
const ProjectList = asyncComponent(() => import('../pages/projectList/index'))
const ProjectSetting = asyncComponent(() => import('../pages/projects/projectSetting'))
const PageFrame = asyncComponent(() => import('../pages/iframe/pageFrame'))
const Square = asyncComponent(() => import('../pages/square/square'))
const CreateSquare = asyncComponent(() => import('../pages/square/createSquare'))
const MySquare = asyncComponent(() => import('../pages/square/mySquare'))

class PageRouter extends React.Component {
  render() {
    return (
      <Switch>
        <App {...this.props}>
          <Route path="/welcome" render={(props)=><WelcomePage {...this.props}/>} />
          <Route path="/create" render={(props)=><CreateProject {...this.props}/>} />
          <Route path="/projects/list" render={(props)=><MyProject {...this.props}/>} />
          <Route path="/chat-official" render={(props)=><AskMe official={true} {...this.props}/>}/>
          <Route path="/askme" render={(props)=><AskMe {...this.props}/>} />
          <Route path="/view" render={(props)=><ProjectList {...this.props}/>} />
          <Route path="/projectSetting" render={(props)=><ProjectSetting {...this.props}/>} />
          <Route path="/profile" render={(props)=><PageFrame {...this.props} href="https://www.copilot365.co/account/my-account"/>} />
          <Route path="/pricing" render={(props)=><PageFrame {...this.props} href="https://www.copilot365.co/plans-pricing"/>} />
          <Route path="/billing" render={(props)=><PageFrame {...this.props} href="https://www.copilot365.co/account/my-subscriptions"/>} />
           <Route path="/square" render={(props)=><Square {...this.props}/>} />
          <Route path="/s-create" render={(props)=><CreateSquare {...this.props}/>} />
          <Route path="/my-square" render={(props)=><MySquare {...this.props}/>} />
        </App>
      </Switch>
    )
  }
}
const mapStateToProps = state => {
  return state
}
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(actions, dispatch)
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withRouter(PageRouter))