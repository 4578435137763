import React from 'react';
// import { Button } from 'antd';
import { Dropdown } from 'antd';
import { UserOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import '../style/component.less';
import fetch from '../fetch/axios';

class chatHelper extends React.Component {
  state = {
    tabIndex: 0,
    helperData: []
  }

  componentDidMount() {
    this.getHelperList();
  }

  getHelperList() {
    fetch.get(`${process.env.URL}/Chat/GPTTemplater`).then(res => {
      if (res && res.success) {
        console.log(res);
        this.setState({
          helperData: res.data || [],
        })
      } else {
        console.log(res.message);
      }
    })
  }
  // 收藏
  markStar(helpD) {
    // console.log(helpD);
    let obj = {
      id: helpD.id,
    }
    fetch.post(`${process.env.URL}/Chat/GPTTemplater/Toggle`, obj).then(res => {
      if (res && res.success) {
        // console.log(res);
        this.setState({
          helperData: res.data || [],
        })
      } else {
        console.log(res.message);
      }
    })
  }

  helperClick(item) {
    // console.log(item, index);
    this.props.helperSearch(item?.id || 0);
  }

  render() {
    return (
      <div className="chat_helper">
        <div className='chat_lt'>
          {this.state.helperData.map((item, index) => {
            if (index >= 4) return null;
            return (
              <div className={`chat_helper_item`} key={index} onClick={() => { this.helperClick(item) }}>
                <div className='chat_emoji'>{item.img}</div>
                {item.title}
              </div>
            )
          })}
        </div>
        <div className='chat_gt'>
          <Dropdown
            trigger={['click']}
            dropdownRender={menu => {
              return (
                <div className='chat_h_more_dropdown'>
                  <div className='chat_h_more_title'>Choose your chat assistant</div>
                  <div className='chat_h_more_main'>
                    {this.state.helperData.map((item, index) => {
                      return (
                        <div className={`chat_helper_d_item`} key={index} onClick={() => { this.helperClick(item) }}>
                          <div className='chat_emoji'>{item.img}</div>
                          {item.title}
                          <div className='chat_h_star_box' onClick={(e)=>{ e.stopPropagation(); this.markStar(item) }}>
                            {item.user_collect ? <StarFilled className='chat_h_star'/>: <StarOutlined className='chat_h_star stared'/>}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            }}
            placement="topRight"
          >
            <div className='chat_h_more_btn'>
              <UserOutlined className='chat_h_more_icon'/>
              {/* <CheckOutlined />
               */}
               <div className='chat_h_more_btn_text'>View More</div>
            </div>
          </Dropdown>
        </div>
      </div>
    )
  }
}

export default chatHelper