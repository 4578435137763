import React from 'react';
import { Form, Input, Button } from 'antd';

class DynamicForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValues: {}, // 表单数值
    };
  }
  componentDidMount() {
    console.log(this.props.fData)
  }

  handleFormSubmit = (values) => {
    console.log('Form submitted:', values);
    // 在这里可以处理表单提交的逻辑
  };

  renderFormFields = () => {
    const { fields } = this.props.fData;
    const { formValues } = this.state;

    return fields.map((field) => {
      const { type, label, name, defaultValue, required, options } = field;

      if (type === 'Text') {
        return (
          <Form.Item
            key={name}
            label={name}
            name={label}
            initialValue={defaultValue}
            rules={[
              { required: required, message: `${label} is required` },
            ]}
          >
            <Input />
          </Form.Item>
        );
      }

      // 在这里可以根据不同的字段类型添加其他表单项，如下拉选择、日期选择等

      return null;
    });
  };

  renderFormButtons = () => {
    const { buttons } = this.props.fData;

    return buttons.map((button) => {
      const { label, type, name } = button;

      if (type === 'button') {
        return (
          <Button key={name} type="primary" htmlType="submit">
            {name}
          </Button>
        );
      }

      // 在这里可以根据不同的按钮类型添加其他按钮，如取消按钮等

      return null;
    });
  };

  render() {
    const { title, description } = this.props.fData;

    return (
      <div>
        <h1>{title}</h1>
        <p>{description}</p>
        <Form onFinish={this.handleFormSubmit}>
          {this.renderFormFields()}
          <Form.Item>
            {this.renderFormButtons()}
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default DynamicForm;
