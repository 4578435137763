import React from 'react'
import { MenuFoldOutlined, MenuUnfoldOutlined, PlusOutlined, UserOutlined, MoneyCollectOutlined, CreditCardOutlined, LogoutOutlined } from '@ant-design/icons';
import { Layout, Button, Avatar, Popover } from 'antd';
import '../style/header.less';

const { Header } = Layout;

class HeaderBar extends React.Component {
  state = {
  }
  componentDidMount() {
    
  }
  
  changePath(url) {
    this.props.history.push(url);
  }

  render() {
    const {collapsed, smallScreen, moveToOpen, user} = this.props;
    const content = (
      <div className='header_dropdown'>
        <div className='hd_header'>
          <div className='dropdown_btn'>
            <Avatar className='person_box'>{user.shortName}</Avatar>
            <div className='person_name'>{user.name}</div>
          </div>
        </div>
        <div className='hd_line'></div>
        <div className='hd_body'>
          <div className='dropdown_btn' onClick={()=>{ this.changePath('/profile') }}><UserOutlined />My Profile</div>
          <div className='dropdown_btn' onClick={()=>{ this.changePath('/pricing') }}><MoneyCollectOutlined />Pricing</div>
          <div className='dropdown_btn' onClick={()=>{ this.changePath('/billing') }}><CreditCardOutlined />Billing</div>
        </div>
        <div className='hd_line'></div>
        <div className='hd_bottom'>
          <div className='dropdown_btn' onClick={() => {
            // 退出登录
            this.props.userLogOut()
          }}><LogoutOutlined />Log Out</div>
        </div>
      </div>
    );
    return (
      <div className="header_bar">
        <Header>
          <div className='header_lt'>
            {smallScreen ? React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'nav_close',
              onClick: () => {
                if (!collapsed && moveToOpen) {
                  this.props.setNavData({collapsed: false, moveToOpen: false})
                  return
                }
                this.props.setNavData({collapsed: !collapsed, moveToOpen: !collapsed})
              },
            }): null}
          </div>
          <div className='header_gt'>
            <Button type="primary" icon={<PlusOutlined />} className='add_btn' onClick={()=>{ this.changePath('/create') }}>
              Create Bot
            </Button>
            <Popover content={content} trigger="click" arrow={false} placement="bottomRight">
              <Avatar className='person_box'>{user.shortName}</Avatar>
            </Popover>
          </div>
        </Header>
      </div>
    )
  }
}

export default HeaderBar